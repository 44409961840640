import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import {useForm} from 'react-hook-form';
import Select from 'Components/Select';
import Button from 'Components/Button';
import s from './HearingCareSelect.module.scss';

const HearingCareSelect = ({options}) => {
    const {t} = useTranslation();

    const {
        handleSubmit,
        control,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            select: '',
        },
    });

    const onSubmit = ({select}) => {
        const redirect = select?.url;
        if(redirect) {
            window.location.href = redirect;
        }
    };

    return (
        <div className={s['HearingCareSelect']}>
            <form
                className={s['HearingCareSelect__Form']}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Select
                    className={s['HearingCareSelect__Select']}
                    options={options}
                    name="select"
                    label={t('hearingCareSelect.label')}
                    placeholder={t('hearingCareSelect.placeholder')}
                    control={control}
                    labelInside={false}
                    theme="Simple"
                />
                <Button
                    className={s['HearingCareSelect__Button']}
                    type="submit"
                    atts={{'aria-label': t('hearingCareSelect.button')}}
                    modifier="Primary"
                />
            </form>
        </div>
    );
};

HearingCareSelect.propTypes = {
    options: PropTypes.array,
};

HearingCareSelect.defaultProps = {
    options: [],
};

export default HearingCareSelect;
