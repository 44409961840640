import React from 'react';
import PropTypes from 'prop-types';
import Base from 'Layouts/Base';
import Hero from 'Components/Hero';
import HearingCareGuidance from 'Components/HearingCareGuidance';
import Modules from 'Components/Modules';
import _ from 'lodash';
import s from './LandingHorsellinjen.module.scss';

const LandingHorsellinjen = ({hero, hearingCareGuidance, modules}) => (
    <div className={s['LandingHorsellinjen']}>
        <Hero
            {...hero}
            hasEntrances={!_.isEmpty(hearingCareGuidance)}
        />

        {!_.isEmpty(hearingCareGuidance) &&
            <HearingCareGuidance {...hearingCareGuidance} isOverlap={true} />
        }

        {!_.isEmpty(modules) &&
            <Modules {...modules} />
        }
    </div>
);

LandingHorsellinjen.propTypes = {
    hero: PropTypes.object,
    hearingCareGuidance: PropTypes.object,
    modules: PropTypes.object,
};

LandingHorsellinjen.defaultProps = {
    hero: null,
    hearingCareGuidance: null,
    modules: null,
};

export default Base(LandingHorsellinjen);
