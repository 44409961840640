import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {sanitizeBasicHTML} from 'utils/html';
import HearingCareSelect from 'Components/HearingCareSelect';
import s from './HearingCareGuidance.module.scss';

const HearingCareGuidance = ({ title, items, isOverlap }) => {
    const total = items.length;

    const hasTitle = !_.isEmpty(title);

    const classes = classNames(
        s['HearingCareGuidance'],
        {[s['HearingCareGuidance--Overlap']]: isOverlap},
    );

    return (
        <div className={classes}>
            <div className={s['HearingCareGuidance__Wrap']}>
                {hasTitle &&
                    <h2 className={s['HearingCareGuidance__Title']}>{title}</h2>
                }

                <ul className={s['HearingCareGuidance__List']}>
                    {items.map((item, index) => (
                        <li
                            key={index}
                            className={s['HearingCareGuidance__Item']}
                        >
                            <Card
                                {...item}
                                heading={hasTitle ? 'h3' : 'h2'}
                                isFull={index === total - 1 && index % 2 == 0}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

HearingCareGuidance.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
    isOverlap: PropTypes.bool,
};

HearingCareGuidance.defaultProps = {
    title: '',
    items: [],
    isOverlap: false,
};

const Card = ({heading, title, link, text, isFull, icon, hearingCareSelect}) => {

    const url = link?.url;
    const target = link?.target;
    const linkText = link?.title || title;

    const hasLink = !_.isEmpty(url);

    const sanitizedText = sanitizeBasicHTML(text);

    const Tag = heading;

    const classes = classNames(
        s['HearingCareGuidance__Card'],
        {[s['HearingCareGuidance__Card--Full']]: isFull},
        {[s['HearingCareGuidance__Card--Half']]: !isFull},
        {[s['HearingCareGuidance__Card--HasLink']]: hasLink},
        {[s['HearingCareGuidance__Card--NoLink']]: !hasLink},
        {[s['HearingCareGuidance__Card--Icon']]: !_.isEmpty(icon)},
        {[s[`HearingCareGuidance__Card--${_.upperFirst(icon)}`]]: !_.isEmpty(icon)},
    );

    return (
        <div className={classes}>
            {!_.isEmpty(url) &&
                <a
                    className={s['HearingCareGuidance__CardLink']}
                    href={url}
                    target={target}
                    aria-label={linkText}
                />
            }

            <div className={s['HearingCareGuidance__CardContent']}>
                <Tag className={s['HearingCareGuidance__CardTitle']}>{title}</Tag>

                {!_.isEmpty(text) &&
                    <div
                        className={s['HearingCareGuidance__CardText']}
                        dangerouslySetInnerHTML={{__html: sanitizedText}}
                    />
                }
            </div>

            {!_.isEmpty(hearingCareSelect) &&
                <div className={s['HearingCareGuidance__CardSelect']}>
                    <HearingCareSelect {...hearingCareSelect} />
                </div>
            }
        </div>
    );
};

Card.propTypes = {
    heading: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
    icon: PropTypes.string,
    isFull: PropTypes.bool,
    hearingCareSelect: PropTypes.object,
};

Card.defaultProps = {
    heading: 'h3',
    title: '',
    link: {},
    text: '',
    icon: '',
    isFull: false,
    hearingCareSelect: {},
};

export default HearingCareGuidance;
