import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ReactSelect from 'react-select';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import Error from 'Components/Error';
import s from './Select.module.scss';

const Select = ({
    name,
    label,
    className,
    id,
    placeholder,
    options,
    required,
    tooltip,
    isMulti,
    isClearable,
    control,
    errors,
    defaultValue,
    labelHidden,
    theme,
    width,
}) => {
    const {t} = useTranslation();

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            boxShadow: 'none',
            minHeight: '56px',
            borderWidth: '1px',
            borderColor: state.selectProps.isError
                ? '#cf171a !important'
                : state.isFocused
                    ? '#0867f2'
                    : (theme === 'Simple' ? '#e0e0e0' : '#757575'),
            backgroundColor: state.selectProps.isError ? '#fdf4f2' : 'white',
            borderRadius: '4px',
            ':hover': {
                borderColor: '#9cc2fa',
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '8px 16px',
            lineHeight: '20px',
        }),
        multiValue: (provided) => ({
            ...provided,
            borderRadius: '4px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            paddingLeft: '10px',
            paddingTop: '6px',
            paddingBottom: '6px',
            paddingRight: '10px',
            minHeight: '28px',
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            borderTopRightRadius: '0 !important',
            borderBottomRightRadius: '0 !important',
            backgroundColor: '#0867f2',
            whiteSpace: 'normal',
            lineHeight: '1.2',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'white',
            borderTopLeftRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
            backgroundColor: '#0867f2',
            cursor: 'pointer',
            ':hover': {
                backgroundColor: '#0e38c7',
            },
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: '2',
        }),
        clearIndicator: (provided) => ({
            ...provided,
            color: 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: (theme === 'Simple' ? 'none' : '#bdbdbd'),
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'black',
            paddingTop: '12px',
            paddingBottom: '12px',
            paddingLeft: '12px',
            paddingRight: '12px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#0867f2' : 'white',
            ':hover': {
                backgroundColor: state.isSelected ? '#0e38c7' : '#eff5ff',
            },
        }),
    };

    const isError = _.get(errors, name, false);

    const newPlaceholder = _.isEmpty(placeholder) ? t('select.placeholder') : placeholder;

    const fieldId = _.isEmpty(id) ? name : id;

    return (
        <div
            className={classNames(s['Select'], className, {
                [s['Select--LabelHidden']]: labelHidden,
                [s['Select--Error']]: isError,
                [s[`Select--${width}`]]: width,
            })}>
            <label
                htmlFor={fieldId}
                className={classNames(s['Select__Label'], {
                    [s['Select__Label--Required']]: required,
                    'sr-only': labelHidden,
                })}>
                {label}
                {tooltip && (
                    <span data-tip={tooltip} className={s['Select__Tooltip']} />
                )}
            </label>
            <Tooltip
                className={s['Select__ReactTooltip']}
                place="top"
                type="dark"
                effect="solid"
                multiline={true}
            />
            <Controller
                render={({field}) => (
                    <ReactSelect
                        {...field}
                        isMulti={isMulti}
                        options={options}
                        styles={selectStyles}
                        placeholder={newPlaceholder}
                        isClearable={isClearable}
                        isError={isError}
                        defaultValue={defaultValue}
                    />
                )}
                id={fieldId}
                name={name}
                control={control}
                rules={{ required: required }}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                message={t('select.error')}
                as={<Error />}
            />
        </div>
    );
};

Select.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    labelHidden: PropTypes.bool,
    placeholder: PropTypes.string,
    options: PropTypes.array.isRequired,
    required: PropTypes.bool,
    tooltip: PropTypes.string,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object,
    defaultValue: PropTypes.string,
    theme: PropTypes.string,
    width: PropTypes.string,
};

Select.defaultProps = {
    name: '',
    id: '',
    className: '',
    labelHidden: true,
    placeholder: null,
    options: [],
    required: false,
    tooltip: '',
    isMulti: false,
    isClearable: true,
    control: {},
    errors: {},
    defaultValue: '',
    theme: 'Default',
    width: '',
};

export default Select;
